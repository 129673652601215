<template>
  <div>
    <div class="footers">
      <div class="message">
        {{ text }}
      </div>
      <div class="pay">
        <van-button type="info" round size="small" class="footer-btn" @click="debounceGenerateOrder">
          立即支付
        </van-button>
        <div class="footer-text">
          合计：
          <span class="red-color">¥ {{ price }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { userModel } from '@/api/user'
import { WX_CONST } from '@/utils/constant'
import { debounce } from "../../utils/index"
import { Toast } from 'vant'
export default {
  name: 'Pay',
  props: {
    text: {
      type: String,
      default: ''
    },
    price: {
      type: String,
      default: ''
    },
    orderTypeKey: {
      type: Number,
      default: 2
    },
    orderId: {
      type: Number,
      default: -1
    },
    validator: {
      type: Function,
      default: () => true
    }
  },
  mounted() {
    if (window.location.href.indexOf("?#") === -1) {
      window.location.replace(window.location.href.replace("#", "?#"))
    }
  },
  methods: {
     debounceGenerateOrder:debounce(function(){
      this.generateOrder()
    },500,true),
    async generateOrder() {
      Toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      })
      const valid = this.validator()
      if (valid) {
        const { code, data ,msg} = await userModel.createPayOrder({ orderId: this.orderId, orderTypeKey: this.orderTypeKey })
        if (code == 200 && msg == 'mock_pay_success') {
          console.log('互联网医院')
          this.$router.push({ 
            path: '/home' })
          Toast.clear()
        }
        else{
          Toast.clear()
        }
        this.onBridgeReady(data)
      }
    },
    onBridgeReady({ sign, signType, prePayid, nonceStr, timeStamp }) {
      // eslint-disable-next-line no-undef
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest', {
          appId: WX_CONST.appid,
          // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          timeStamp: timeStamp.toString(),
          // 支付签名随机串，不长于 32 位
          nonceStr,
          // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          package: `prepay_id=${ prePayid }`,
          // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'F
          signType,
          // 支付签名
          paySign: sign
        },
        (res) => {
          if (res.err_msg === 'get_brand_wcpay_request:ok') {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            // this.$emit('success', res)
            this.queryOrderStatus(prePayid)
          } else {
            this.$toast('支付失败！')
          }
        })
    },
    pay({ sign, signType, prePayid, nonceStr, timeStamp }) {
      // wx.config({
      //   debug: false,
      //   appId: WX_CONST.appid,
      //   timestamp: timeStamp.toString(),
      //   nonceStr,
      //   signature: res.data.signature,
      //   jsApiList: ['chooseWXPay']
      // })
      /*eslint-disable*/
      wx.chooseWXPay({
        appId: WX_CONST.appid,
        // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
        timeStamp: timeStamp.toString(),
        // 支付签名随机串，不长于 32 位
        nonceStr,
        // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
        package: `prepay_id=${ prePayid }`,
        // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
        signType,
        // 支付签名
        paySign: sign,
        // 支付成功后的回调函数
        success: (res) => {
          this.$emit('success', res)
          // this.queryOrderStatus(prePayid)
        },
        error: (error) => {
          console.log('error:', error)
          this.$toast('支付失败！')
        }
      })
    },
    async queryOrderStatus(prePayid) {
      const { data } = await userModel.queryOrder(prePayid)
      if (data.payState === 2) {
        this.$emit('success')
      } else {
        this.$toast('您的订单支付失败，请重新支付！')
      }
    }
  }
}
</script>

<style scoped lang="less">
.footers {
  position: fixed;
  bottom: 0;
  //display: flex;
  //flex-direction: row-reverse;
  width: 100%;
  height: 198px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 32px;
  box-sizing: border-box;

  .message {
    line-height: 1.7;
    color: #F83A1B;
  }

  .pay {
    display: flex;
    flex-direction: row-reverse;

    .footer-text {
      line-height: 68px;
      margin-right: 20px;
    }

    .footer-btn {
      width: 182px;
      font-size: 28px;
    }
  }

}
</style>
